<template>
  <v-layout wrap>
    <v-flex xs12>
      <small><b>Local</b></small>
    </v-flex>
    <v-flex xs4>
      <translate
        v-model="localLabel"
        :languages="languages"
        label="Alias"
        @input="handleChangeIsModified"
      />
    </v-flex>
    <v-flex xs4>
      <small>Fondo</small>
      <select-image
        style="margin-top:0px"
        :image="localBackground"
        folder="turismo"
        :onChange="handleChangeLocalBackground"
      />
    </v-flex>
    <v-flex xs4>
      <small>Mapa</small>
      <select-image
        style="margin-top:0px"
        :image="localMap"
        folder="turismo"
        :onChange="handleChangeLocalMap"
      />
    </v-flex>
    <v-flex xs12>
      <small><b>Comarcal</b></small>
    </v-flex>
    <v-flex xs4>
      <translate
        v-model="comarcalLabel"
        :languages="languages"
        label="Alias"
        @input="handleChangeIsModified"
      />
    </v-flex>
    <v-flex xs4>
      <small>Fondo</small>
      <select-image
        style="margin-top:0px"
        :image="comarcalBackground"
        folder="turismo"
        :onChange="handleChangeComarcalBackground"
      />
    </v-flex>
    <v-flex xs4>
      <small>Mapa</small>
      <select-image
        style="margin-top:0px"
        :image="comarcalMap"
        folder="turismo"
        :onChange="handleChangeComarcalMap"
      />
    </v-flex>
    <v-flex xs12>
      <small><b>Insular</b></small>
    </v-flex>
    <v-flex xs4>
      <translate
        v-model="insularLabel"
        :languages="languages"
        label="Alias"
        @input="handleChangeIsModified"
      />
    </v-flex>
    <v-flex xs4>
      <small>Fondo</small>
      <select-image
        style="margin-top:0px"
        :image="insularBackground"
        folder="turismo"
        :onChange="handleChangeInsularBackground"
      />
    </v-flex>
    <v-flex xs4>
      <small>Mapa</small>
      <select-image
        style="margin-top:0px"
        :image="insularMap"
        folder="turismo"
        :onChange="handleChangeInsularMap"
      />
    </v-flex>
    <v-flex xs12>
      <v-btn @click="handleSave" color="primary">
        Guardar
      </v-btn>
      <br />
      <br />
      <br />
    </v-flex>
    <!--v-flex xs12>
      {{workspaceConfig}}
    </v-flex-->
  </v-layout>
</template>
<script>
import { mapState } from 'vuex'
import api from '@/services/api'
import Translate from '@/components/Translate'
import SelectImage from '@/components/selectImage/Index'
export default {
  components: {
    Translate,
    SelectImage,
  },
  computed: {
    ...mapState('app',['locale', 'turismoWorkspace']),
  },
  data: () => ({
    languages: [ 'es', 'en', 'de', 'fr'],
    localLabel: { es: '' },
    localBackground: null,
    localMap: null,
    comarcalLabel: { es: '' },
    comarcalBackground: null,
    comarcalMap: null,
    insularLabel: { es: '' },
    insularBackground: null,
    insularMap: null,
    workspaceConfig: {},
  }),
  watch: {
    turismoWorkspace () {
      this.handleGetData()
    },
  },
  mounted () {
    this.handleGetData()
  },
  methods: {
    handleGetData () {
      if (!this.turismoWorkspace) return
      api.getItem('turismo', `v1/private/workspaces/`, this.turismoWorkspace.concat('/config/'))
        .then(response => {
          if (response) {
            this.localLabel = response.LocalLabel ? JSON.parse(response.LocalLabel) : { es: 'Local' }
            this.localBackground = response.LocalBackground
            this.localMap = response.LocalMap

            this.comarcalLabel = response.ComarcalLabel ? JSON.parse(response.ComarcalLabel) : { es: 'Comarcal' }
            this.comarcalBackground = response.ComarcalBackground
            this.comarcalMap = response.ComarcalMap

            this.insularLabel = response.InsularLabel ? JSON.parse(response.InsularLabel) : { es: 'Insular' }
            this.insularBackground = response.InsularBackground
            this.insularMap = response.InsularMap
          }
          this.handleChangeIsModified()
        })
    },
    handleSave () {
      api.updateItem('turismo', `v1/private/workspaces/`, this.turismoWorkspace.concat('/config/'), this.workspaceConfig)
        .then(() => {
        })
    },
    handleChangeIsModified () {
      this.workspaceConfig = {
        LocalLabel: this.localLabel,
        ComarcalLabel: this.comarcalLabel,
        InsularLabel: this.insularLabel,

        LocalBackground: this.localBackground,
        LocalMap: this.localMap,
        ComarcalBackground: this.comarcalBackground,
        ComarcalMap: this.comarcalMap,
        InsularBackground: this.insularBackground,
        InsularMap: this.insularMap,
      }
    },
    handleChangeLocalBackground (v) {
      if  (v && v.base64) this.localBackground = v.base64
      this.workspaceConfig.newLocalBackground = v
    },
    handleChangeLocalMap (v) {
      if  (v && v.base64) this.localMap = v.base64
      this.workspaceConfig.newLocalMap = v
    },
    handleChangeComarcalBackground (v) {
      if  (v && v.base64) this.comarcalBackground = v.base64
      this.workspaceConfig.newComarcalBackground = v
    },
    handleChangeComarcalMap (v) {
      if  (v && v.base64) this.comarcalMap = v.base64
      this.workspaceConfig.newComarcalMap = v
    },
    handleChangeInsularBackground (v) {
      if  (v && v.base64) this.insularBackground = v.base64
      this.workspaceConfig.newInsularBackground = v
    },
    handleChangeInsularMap (v) {
      if  (v && v.base64) this.insularMap = v.base64
      this.workspaceConfig.newInsularMap = v
    },
  },
}
</script>
<style scoped>
.flex {
  padding: 10px 40px;
}
</style>

